import React from 'react';
import Layout from '../../components/Layout';
import logo from '../../assets/images/logo.png';

// import pic1 from '../../assets/images/pic01.jpg';
// import pic2 from '../../assets/images/pic02.jpg';
// import pic3 from '../../assets/images/pic03.jpg';
// import pic4 from '../../assets/images/pic04.jpg';
// import pic4 from '../../assets/images/el_jefe2.jpg';
// import pic6 from '../../assets/images/pic06.jpg';
// import pic7 from '../../assets/images/pic07.jpg';

import pic1 from '../../assets/images/el_jefe.png';
import pic2 from '../../assets/images/el_jefe_console.jpg';
import pic3 from '../../assets/images/el_jefe_discs.jpg';
import pic4 from '../../assets/images/el_jefe_band.jpg';
import pic5 from '../../assets/images/el_jefe_stage.jpg';
import pic6 from '../../assets/images/hugh_fadal_band.jpg';
import pic7 from '../../assets/images/jdc.png';
import pic8 from '../../assets/images/jdc.png';

import config from '../../../config';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>The Hugh Fadal Band</h2>
          <p>Texas Rock, Americana & Country</p>
        </div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <section>
            <h3 className="major">The Hugh Fadal Band Discography</h3>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Release</th>
                    <th>Title</th>
                    <th>Where to Listen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2006</td>
                    <td><b>Good Clean Living</b></td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/3sHPaBO8dmgNMMz5ZUlzIZ?si=_fB12MgJQausvLc_0aOlJQ" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/the-hugh-fadal-band-good-clean" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.apple.com/us/album/good-clean-living/391240707" className="icon fa-apple">
                            <span className="label">Apple</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.amazon.com/albums/B0041ZP1W2?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2011</td>
                    <td><b>Hour Glass</b></td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/3veq5YTO2YSY5ueXcyLbFV?si=jYg5RA3pSZ-zuzr1vEIhQw" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://soundcloud.com/sessionswest/sets/the-hugh-fadal-band-good-clean" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://music.apple.com/us/album/hourglass/405115110" className="icon fa-apple">
                            <span className="label">Apple</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.amazon.com/albums/B004CYJ998?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2"></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </section>

          <section className="artist">
            <h3 className="major">Bio</h3>
            <p>
              If there’s anyone who has truly seen miles and miles of Texas,
              it’s Texas’ own Hugh Evan Fadal. A professional hunting/fishing
              guide and singer/songwriter, many of his songs were inspired while
              frequenting some of his home state's finest ranches and rivers.
              With an innate ability to personify themes such as love through
              lyrical comparisons with nature, Fadal’s writing has earned the
              admiration and respect of many listeners. Before graduating from
              the beautiful Southwest Texas State University in 1994, Hugh
              acquired his first guitar and began playing daily.
            </p>

            <span className="image right">
              <img src={pic6} alt="" />
            </span>
            <p>
              Four years, three bands, and countless rehearsals later, the Hugh Fadal Band
              went on to win the 1998 “Texas State Battle of the Bands” as a
              result of ear catching original songs powered by razor sharp
              guitar work and a dynamic rhythm section. After releasing the
              first album Hourglass, the Hugh Fadal Band began polishing an
              energetic and lyrical live performance night after night
              throughout the Lone Star State, playing festivals, clubs, and
              private engagements. An earthy knack for lyrics combined with
              melodic guitar rhythms, Fadal’s music is simply universal. Whether
              performing solo or rocking with the band, his shows have something
              for everybody. Currently, Fadal performs throughout Texas as well
              as Colorado, enjoying time with his wife and family; hunting,
              fishing, and collecting Native American artifacts.
            </p>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
